import { CustomTooltipSmall } from 'components/shared/CustomTooltip';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserSessionSelector } from 'store/UserSession';
import { PIXLINK_STATUS } from 'utils/Constants';
export function ClientHistoricalBacklinkingsTable({
  shownNetlinkingFilteredList,
  nameSearch,
  onNetlinkingContactFilterChange,
  contactFilter,
  sendNetlinkingExport,
  ordersPerPage,
  removeNetlinkingId,
  columns,
  selectedStatus,
  sendLengthArrayShown,
}) {
  const { t } = useTranslation();
  let { user } = useSelector(UserSessionSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = ordersPerPage || 20;
  const [statusSortedItems, setstatusSortedItems] = useState(null);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortedItems, setSortedItems] = useState([]);
  const [tableExport, setTableExport] = useState([]);

  useEffect(() => {
    if (selectedStatus?.includes('')) {
      sendLengthArrayShown(
        shownNetlinkingFilteredList.filter((item) =>
          item.domain.toLowerCase().includes(nameSearch.toLowerCase())
        ).length
      );
    } else {
      sendLengthArrayShown(
        statusSortedItems !== null
          ? statusSortedItems.filter((item) =>
              item.domain.toLowerCase().includes(nameSearch.toLowerCase())
            ).length
          : sortedItems.filter((item) =>
              item.domain.toLowerCase().includes(nameSearch.toLowerCase())
            ).length
      );
    }
  }, [statusSortedItems, shownNetlinkingFilteredList, nameSearch]);

  useEffect(() => {
    const sorted = [...shownNetlinkingFilteredList].sort((a, b) => {
      if (sortColumn === 'domain') {
        return sortDirection === 'asc'
          ? a.domain.localeCompare(b.domain)
          : b.domain.localeCompare(a.domain);
      } else if (sortColumn === 'languageId') {
        return sortDirection === 'asc'
          ? a.languageId - b.languageId
          : b.languageId - a.languageId;
      } else if (sortColumn === 'themesList') {
        return sortDirection === 'asc'
          ? (a.themesList || '').localeCompare(b.themesList || '')
          : (b.themesList || '').localeCompare(a.themesList || '');
      } else if (sortColumn === 'topicsList') {
        return sortDirection === 'asc'
          ? (a.topicsList || '').localeCompare(b.topicsList || '')
          : (b.topicsList || '').localeCompare(a.topicsList || '');
      } else if (sortColumn === 'contact') {
        return sortDirection === 'asc'
          ? (getEmailPart(a.contact) || '').localeCompare(
              getEmailPart(b.contact) || ''
            )
          : (getEmailPart(b.contact) || '').localeCompare(
              getEmailPart(a.contact) || ''
            );
      } else if (sortColumn === 'contactName') {
        return sortDirection === 'asc'
          ? (getNamePart(a.contact) || '').localeCompare(
              getNamePart(b.contact) || ''
            )
          : (getNamePart(b.contact) || '').localeCompare(
              getNamePart(a.contact) || ''
            );
      } else if (sortColumn === 'contactedAt') {
        return sortDirection === 'asc'
          ? (a.contactedTime || '').localeCompare(b.contactedTime || '')
          : (b.contactedTime || '').localeCompare(a.contactedTime || '');
      } else if (sortColumn === 'kwPosition') {
        return sortDirection === 'asc'
          ? (a.kwPositioned || 0) - (b.kwPositioned || 0)
          : (b.kwPositioned || 0) - (a.kwPositioned || 0);
      } else if (sortColumn === 'Trafic') {
        return sortDirection === 'asc'
          ? (a.trafic || 0) - (b.trafic || 0)
          : (b.trafic || 0) - (a.trafic || 0);
      } else if (sortColumn === 'tF') {
        return sortDirection === 'asc'
          ? (a.tF || 0) - (b.tF || 0)
          : (b.tF || 0) - (a.tF || 0);
      } else if (sortColumn === 'cF') {
        return sortDirection === 'asc'
          ? (a.cF || 0) - (b.cF || 0)
          : (b.cF || 0) - (a.cF || 0);
      } else if (sortColumn === 'tarifHr') {
        return sortDirection === 'asc'
          ? (a.tarifHr || 0) - (b.tarifHr || 0)
          : (b.tarifHr || 0) - (a.tarifHr || 0);
      } else if (sortColumn === 'tarifRc') {
        return sortDirection === 'asc'
          ? (a.tarifRc || 0) - (b.tarifRc || 0)
          : (b.tarifRc || 0) - (a.tarifRc || 0);
      } else if (sortColumn === 'platformRC') {
        return sortDirection === 'asc'
          ? (a.platformRC || 0) - (b.platformRC || 0)
          : (b.platformRC || 0) - (a.platformRC || 0);
      } else if (sortColumn === 'platformHR') {
        return sortDirection === 'asc'
          ? (a.platformHR || 0) - (b.platformHR || 0)
          : (b.platformHR || 0) - (a.platformHR || 0);
      }
      return 0;
    });

    setSortedItems(sorted);
  }, [shownNetlinkingFilteredList, sortColumn, sortDirection]);

  useEffect(() => {
    setCurrentPage(1);
  }, [nameSearch]);

  const handleSortToggle = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleThemesListHover = (event, themes) => {
    event.target.textContent = themes;
  };

  useEffect(() => {
    let NetlinkingColorFilterArray = [];

    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_VALIDATED_PIXALIONE)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList
          .filter(
            (obj) =>
              obj.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_ADMIN ||
              obj.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_CONSULTANT
          )
          .map((item) => item.netlinking),
        ...NetlinkingColorFilterArray,
        ,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList
          .filter(
            (obj) => obj.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
          )
          .map((item) => item.netlinking),
        ,
        ...NetlinkingColorFilterArray,
      ];
    }

    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList
          .filter(
            (obj) => obj.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT
          )
          .map((item) => item.netlinking),
        ,
        ...NetlinkingColorFilterArray,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_COMMANDED)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList
          .filter((obj) => obj.spotStatus === PIXLINK_STATUS.TO_BE_COMMANDED)
          .map((item) => item.netlinking),
        ...NetlinkingColorFilterArray,
        ,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.REFUSED_PIXALIONE)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList
          .filter((obj) => obj.spotStatus === PIXLINK_STATUS.REFUSED_PIXALIONE)
          .map((item) => item.netlinking),
        ...NetlinkingColorFilterArray,
        ,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.REFUSED_CLIENT)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList
          .filter((obj) => obj.spotStatus === PIXLINK_STATUS.REFUSED_CLIENT)
          .map((item) => item.netlinking),
        ,
        ...NetlinkingColorFilterArray,
      ];
    }
    if (selectedStatus.includes('') && sortedItems.length > 0) {
      NetlinkingColorFilterArray = sortedItems;
    }
    let filteredArray = NetlinkingColorFilterArray.filter(
      (item) => item !== undefined
    );
    if (
      selectedStatus.length > 0 &&
      !(selectedStatus.length == 1 && selectedStatus.includes(''))
    ) {
      setstatusSortedItems(filteredArray);
    } else if (sortedItems.length > 0) {
      NetlinkingColorFilterArray = sortedItems;
      filteredArray = NetlinkingColorFilterArray.filter(
        (item) => item !== undefined
      );
      setstatusSortedItems(filteredArray);
    }
  }, [selectedStatus]);

  const handleThemesListLeave = (event, themes) => {
    if (themes !== null && themes !== undefined && themes.includes(',')) {
      event.target.textContent = themes.split(',')[0] + '...';
    } else {
      event.target.textContent = themes;
    }
  };

  function netlinkingIsChosen(item) {
    // Check if the item is present in shownNetlinkingFilteredList
    return shownNetlinkingFilteredList.some(
      (chosenItem) => chosenItem.id === item.id
    );
  }

  const transformDate = (dateString) => {
    const parsedDate = new Date(dateString);
    const year = parsedDate.getFullYear();
    const month = ('0' + (parsedDate.getMonth() + 1)).slice(-2);
    const day = ('0' + parsedDate.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  };

  function getEmailPart(inputString) {
    try {
      // Split the inputString using '<' and '>' as delimiters
      const parts = inputString.split('<');

      // Check if the split produced at least two parts
      if (parts.length >= 2) {
        // Get the second part and remove the trailing '>'
        const emailPart = parts[1].replace('>', '').trim();
        return emailPart;
      } else {
        // If the inputString is not in the expected format, return null or an appropriate value
        return inputString;
      }
    } catch (err) {
      console.error('An error occurred:', err.message);
      return '-';
    }
  }

  function getNamePart(inputString) {
    try {
      // Split the inputString using '@' as a delimiter
      const emailRegex = /[\w.-]+@[\w.-]+\.[A-Za-z]{2,}/g;
      let stringWithoutEmail = inputString.replace(emailRegex, '');
      stringWithoutEmail = stringWithoutEmail.replace('>', '');
      stringWithoutEmail = stringWithoutEmail.replace('<', '');
      stringWithoutEmail = stringWithoutEmail.replace('/', '');
      if (stringWithoutEmail.trim().length === 0) {
        return '-';
      }
      return stringWithoutEmail.trim();
    } catch (err) {
      console.error('An error occurred:', err.message);
      return '-';
    }
  }

  const handleNetlinkingContactFilterChange = (value) => {
    // Call the callback function from the Father component to pass the value to the parent
    onNetlinkingContactFilterChange(value);
  };

  const history = useHistory();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems =
    statusSortedItems !== null
      ? statusSortedItems
          .filter((item) =>
            item.domain.toLowerCase().includes(nameSearch.toLowerCase())
          )
          .slice(indexOfFirstItem, indexOfLastItem)
      : sortedItems
          .filter((item) =>
            item.domain.toLowerCase().includes(nameSearch.toLowerCase())
          )
          .slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="table-responsive">
        <div className="box-table-netlinking">
          <table className="table-lists">
            <thead>
              <tr>
                {columns.find((column) => column.name === 'domain')
                  ?.visible && (
                  <th
                    className="cell-fixed-column"
                    onClick={() => handleSortToggle('domain')}
                  >
                    <span className={sortColumn === 'domain' ? 'active' : ''}>
                      {t('Domaine')}
                    </span>
                    {sortColumn === 'domain' ? (
                      sortDirection === 'asc' ? (
                        <i
                          style={{ marginLeft: '3px', marginTop: '5px' }}
                          className="bi bi-chevron-up"
                        />
                      ) : (
                        <i
                          style={{ marginLeft: '3px', marginTop: '5px' }}
                          className="bi bi-chevron-down"
                        />
                      )
                    ) : null}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.id} style={{ cursor: 'pointer' }}>
                  {columns.find((column) => column.name === 'domain')
                    ?.visible && (
                    <td className="cell-fixed-column">
                      <span style={{ marginLeft: '5px' }}>
                        <img
                          style={{ width: '20px', cursor: 'pointer' }}
                          src="Images/icon-recyclage.png"
                          alt="icon-recyclage"
                        />
                      </span>
                      <a
                        href={
                          !item.domain.startsWith('http://') &&
                          !item.domain.startsWith('https://')
                            ? 'https://' + item.domain
                            : item.domain
                        }
                        target="_blank"
                        className={
                          netlinkingIsChosen(item)
                            ? 'domain-container black-row'
                            : 'domain-container grey-row'
                        }
                      >
                        {item.domain}
                      </a>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="box-pagination">
        {sortedItems.length === 0 || currentPage === 1 ? (
          <div style={{ cursor: 'not-allowed' }} className="text-page">
            {t('Précédent')}
          </div>
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            {t('Précédent')}
          </div>
        )}
        <div className="list-page-number">
          {sortedItems.length === 0 ||
          currentPage === Math.ceil(sortedItems.length / itemsPerPage) ? (
            <span style={{ cursor: 'not-allowed' }} className="text-page">
              {t('Suivant')}
            </span>
          ) : (
            <span onClick={() => setCurrentPage(currentPage + 1)}>
              {t('Suivant')}
            </span>
          )}
        </div>
      </div>
    </>
  );
}
