export function getLanguageNameById(id) {
  const idToLanguage = {
    1: 'english',
    2: 'german',
    3: 'spanish',
    4: 'italian',
    6: 'french',
    34: 'Ukraine',
    35: 'Kazakhstan',
    36: 'Finland',
    37: 'Turkey',
    38: 'Portugal',
    39: 'India',
    40: 'Egypt',
    41: 'Brazil',
    42: 'Pakistan',
    43: 'Azerbaijan',
    44: 'Norway',
    45: 'Poland',
    46: 'Philippines',
    47: 'Russia',
    48: 'Denmark',
    49: 'Combodia',
    50: 'Bangladesh',
    51: 'Vietnam',
    52: 'Nigeria',
    53: 'Romaina',
    54: 'sweden',
    55: 'Nepal',
    56: 'Argentina',
    57: 'Netherlands',
    58: 'Indonesia',
    59: 'onne',
    60: 'UAE',
    61: 'Ireland',
    62: 'South Africa',
    63: 'Greece',
    64: 'Mexico',
    65: 'South Korea',
    66: 'Colombia',
    67: 'Romania',
    68: 'Bulgaria',
  };
  return idToLanguage[id];
}

export function getLanguageIdByName(name) {
  const idToLanguage = {
    1: 'english',
    2: 'german',
    3: 'spanish',
    4: 'italian',
    6: 'french',
    34: 'Ukraine',
    35: 'Kazakhstan',
    36: 'Finland',
    37: 'Turkey',
    38: 'Portugal',
    39: 'India',
    40: 'Egypt',
    41: 'Brazil',
    42: 'Pakistan',
    43: 'Azerbaijan',
    44: 'Norway',
    45: 'Poland',
    46: 'Philippines',
    47: 'Russia',
    48: 'Denmark',
    49: 'Combodia',
    50: 'Bangladesh',
    51: 'Vietnam',
    52: 'Nigeria',
    53: 'Romaina',
    54: 'sweden',
    55: 'Nepal',
    56: 'Argentina',
    57: 'Netherlands',
    58: 'Indonesia',
    59: 'onne',
    60: 'UAE',
    61: 'Ireland',
    62: 'South Africa',
    63: 'Greece',
    64: 'Mexico',
    65: 'South Korea',
    66: 'Colombia',
    67: 'Romania',
    68: 'Bulgaria',
  };
  const languageToId = {};
  for (const id in idToLanguage) {
    const language = idToLanguage[id];
    languageToId[language] = parseInt(id);
  }
  return languageToId[name];
}

export function getLanguageRefById(id) {
  const idToLanguage = {
    1: 'En',
    2: 'De',
    3: 'Es',
    4: 'It',
    5: 'Pt',
    6: 'Fr',
  };
  return idToLanguage[id];
}

export function getLanguageIdByRef(name) {
  const idToLanguage = {
    1: 'En',
    2: 'De',
    3: 'Es',
    4: 'It',
    5: 'Pt',
    6: 'Fr',
    34: 'Ukraine',
    35: 'Kazakhstan',
    36: 'Finland',
    37: 'Turkey',
    38: 'Portugal',
    39: 'India',
    40: 'Egypt',
    41: 'Brazil',
    42: 'Pakistan',
    43: 'Azerbaijan',
    44: 'Norway',
    45: 'Poland',
    46: 'Philippines',
    47: 'Russia',
    48: 'Denmark',
    49: 'Combodia',
    50: 'Bangladesh',
    51: 'Vietnam',
    52: 'Nigeria',
    53: 'Romaina',
    54: 'Sweden',
    55: 'Nepal',
    56: 'Argentina',
    57: 'Netherlands',
    58: 'Indonesia',
    59: 'Onne',
    60: 'UAE',
    61: 'Ireland',
    62: 'South Africa',
    63: 'Greece',
    64: 'Mexico',
    65: 'South Korea',
    66: 'Colombia',
    67: 'Romania',
    68: 'Bulgaria',
  };

  const languageToId = {};
  for (const id in idToLanguage) {
    const language = idToLanguage[id];
    languageToId[language] = parseInt(id);
  }
  return languageToId[name];
}